<template>
  <div class="client-setup-container">
    <b-form-select v-model="selectedClient" class="email-client-select">
      <option v-for="option in emailClients" :key="option.name" :value="option.value">
        <label>{{ option.name }}</label>
      </option>
    </b-form-select>

    <div class="client-instructions pt-4">
      <!---------- APPLE (iPhone) ----------------------------------------------------------------->
      <template v-if="selectedClient === 'apple-iphone-ipad'">
        <p class="instructions-client-name">
          Apple Devices (iPhone/iPad):
        </p>
        <ol>
          <li>
            Go to Settings > Mail, then tap Accounts.
          </li>
          <li>
            Tap Add Account, tap Other, then tap Add Mail Account.
          </li>
          <li>
            Enter your name, email address, password, and a description for your account.
          </li>
          <li>
            Tap Next. Mail will try to find the email settings and finish your account setup.
            If Mail finds your email settings, tap Done to complete your account setup.
          </li>
        </ol>

        <p>
          Issues setting up your email? Visit
          <a
            href="https://support.apple.com/en-us/102619"
            target="_blank"
          >
            Apple's Add Email Accounts
          </a>
          page for help.
        </p>
      </template>

      <!---------- APPLE (MAC) -------------------------------------------------------------------->
      <template v-else-if="selectedClient === 'apple-mac-os'">
        <p class="instructions-client-name">
          Apple Mail (macOS - current version: Ventura 13):
        </p>

        <ol>
          <li>
            In Mail, choose Mail > Add Account.
          </li>
          <li>
            Select an account type.
          </li>
          <li>
            Enter your account information.
          </li>
          <li>
            Make sure the Mail checkbox is selected for the account
          </li>
          <li>
            Add in the incoming/outgoing server info. Ensure that the email/password is added for
            BOTH the outgoing and incoming server sections.
          </li>
        </ol>

        <p>
          Issues setting up your email? Visit the
          <a
            href="https://support.apple.com/guide/mail/add-email-accounts-mail35803/mac#:~:text=In%20Mail%2C%20choose%20Mail%20%3E%20Add,use%20that%20account%20with%20Mail"
            target="_blank"
          >
            Apple Mail User Guide
          </a>
          for help.
        </p>
      </template>

      <!---------- GMAIL -------------------------------------------------------------------------->
      <template v-else-if="selectedClient === 'gmail'">
        <p class="instructions-client-name">
          Gmail:
        </p>
        <p>
          You can receive an email from another account on Gmail by adding that email address as
          an alias or a "Send mail as" address.
        </p>
        <ol>
          <li>
            Open Gmail.
          </li>
          <li>
            Click on the gear icon in the top right corner and select "Settings."
          </li>
          <li>
            Click ‘see all settings'.
          </li>
          <li>
            Click on the "Accounts and Import" tab.
          </li>
          <li>
            Click on “Add a mail account”
          </li>
          <li>
            Enter the email address that you want to send email from and click "Next."
          </li>
          <li>
            Click on “Import emails from my other account (POP3)” and click “Next”.
          </li>
          <li>
            Fill out the email and password information
          </li>
          <li>
            Use the Outgoing POP server settings below.
          </li>
          <li>
            Click “Add Account.”
          </li>
          <li>
            Under “Your mail account has been added” click “Yes, I want to be able to send mail as”, click “Next”.
          </li>
          <li>
            Under “Add another email address you own” uncheck “Treat as Alias”
          </li>
          <li>
            Follow the prompts to verify the email address.
          </li>
          <li>
            Use the Incoming POP server settings below.
          </li>
          <li>
            Click “Add Account.”
          </li>
          <li>
            Under “Accounts and import” click “verify” this will send an email verification link you need to click on to be able to use your email account.
          </li>
        </ol>

        <p>
          Once the email address is verified, you can start receiving email from that address in
          your primary Gmail inbox. You can also set up the account to forward all incoming emails
          to your primary account by going to the settings of the account you want to forward from
          and setting up the forward there.
        </p>

        <p>
          Issues setting up your email? Visit the
          <a
            href="https://support.google.com/mail/answer/56256?hl=en"
            target="_blank"
          >
            Gmail Help Center
          </a>
          for help.
        </p>
      </template>

      <!---------- OUTLOOK (Webmail Version) ------------------------------------------------------>
      <template v-else-if="selectedClient === 'outlook-webmail'">
        <p class="instructions-client-name">
          Outlook (Webmail Version):
        </p>

        <ol>
          <li>
            Click on the gear icon on the upper right part of the mailbox page and choose View all
            Outlook settings
          </li>
          <li>
            Choose the Sync email option, and click Other email accounts
          </li>
          <li>
            Select Manually configure account settings and click OK
          </li>
          <li>
            Select type of the account IMAP/SMTP or POP3/SMTP and click OK (IMAP/SMTP is recommend)
          </li>
          <li>
            Enter Incoming/Outgoing Server info
          </li>
        </ol>

        <p>
          Issues setting up your email? Visit the
          <a
            href="https://support.microsoft.com/en-us/office/make-outlook-the-default-program-for-email-contacts-and-calendar-ff7990c4-54c4-4390-8fe3-c0285226f021"
            target="_blank"
          >
            Microsoft Outlook Support
          </a>
          page for help.
        </p>
      </template>

      <!---------- OUTLOOK (Desktop Version) ------------------------------------------------------>
      <template v-else-if="selectedClient === 'outlook-desktop'">
        <p class="instructions-client-name">
          Outlook (Desktop Version):
        </p>

        <ol>
          <li>
            Open Outlook on your computer.
          </li>
          <li>
            Click on the "File" tab in the top left corner of the screen.
          </li>
          <li>
            From the dropdown menu, select "Add Account."
          </li>
          <li>
            In the Add Account window, enter your email address and click "Connect."
          </li>
          <li>
            Select "IMAP" or "POP" as the account type. IMAP is recommended as it allows you to
            access your emails on multiple devices
          </li>
        </ol>

        <p>
          Issues setting up your email? Visit the
          <a
            href="https://support.microsoft.com/en-us/office/make-outlook-the-default-program-for-email-contacts-and-calendar-ff7990c4-54c4-4390-8fe3-c0285226f021"
            target="_blank"
          >
            Microsoft Outlook Support
          </a>
          page for help.
        </p>
      </template>

      <p>Once your email is set up, we’ll check that you’re receiving emails.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ClientSetup',
  data() {
    return {
      selectedClient: 'apple-iphone-ipad',
      emailClients: [
        {
          name: 'Apple Devices (iPhone/iPad)',
          value: 'apple-iphone-ipad',
        },
        {
          name: 'Apple Mail (macOS - current version: Ventura 13)',
          value: 'apple-mac-os',
        },
        {
          name: 'Gmail',
          value: 'gmail',
        },
        {
          name: 'Outlook (Webmail Version)',
          value: 'outlook-webmail',
        },
        {
          name: 'Outlook (Desktop Version',
          value: 'outlook-desktop',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.client-setup-container {
  .header {
    font-weight: bold;
  }

  .email-client-select {
    width: auto;
    min-width: 25vw;
  }

  .instructions-client-name {
    font-weight: bold;
  }
}

@media only screen and (max-width: 767px) {
  .client-setup-container {

    .email-client-select {
      width: 100%;
      min-width: 100%;
    }
  }
}
</style>
